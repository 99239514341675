import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  skew?: boolean;
  hero?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
};

const Section = ({ children, className, skew, noPaddingTop, noPaddingBottom, ...rest }: Props) => {
  return (
    <section className={`${skew ? 'relative my-8 -skew-y-6 md:my-24' : ''} ${className}`} {...rest}>
      <div
        className={` 
        ${skew ? 'skew-y-6 py-24 md:py-40' : ''} 
      ${noPaddingTop ? 'pt-0' : 'pt-16 md:pt-24 lg:pt-32'}
      ${noPaddingBottom ? 'pb-0' : 'pb-16 md:pb-24 lg:pb-32'}`}
      >
        {children}
      </div>
    </section>
  );
};

export default Section;
