import React from 'react';
import HeroBackground from './HeroBackground';

type Props = {
  filled?: boolean;
};

const HeaderSpacer = ({ filled }: Props) => {
  return (
    <div className={`${filled ? 'bg-blue' : ''} relative h-24 overflow-hidden sm:h-28`}>
      {filled && <HeroBackground />}
    </div>
  );
};

export default HeaderSpacer;
