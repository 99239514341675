import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useLocales } from '../hooks/useLocales';
import Container from './Container';
import Logo from './Logo';
import { navigation } from '../constants/navigation';
type Props = {};

const Footer = (props: Props) => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  const { locales } = useLocales();

  const { userNavigation, mainNavigation, legalNavigation } = navigation;

  return (
    <footer className="flex-shrink-0 bg-slate-50 pb-10">
      <Container>
        <div className="flex flex-col gap-8 pt-10 md:flex-row md:gap-24">
          <div className="grow">
            <Logo variant="dark" />
            <div className="prose mt-8 text-sm">
              <div dangerouslySetInnerHTML={{ __html: t('common.address') }} />
              {/* <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: t('common.acpr_licence') }}
              /> */}
            </div>
          </div>
          <div>
            <h4 className="mb-4 font-semibold">{t('footer.title_navigation')}</h4>
            <ul>
              {mainNavigation.map((link, i) => (
                <li key={i}>
                  <Link
                    to={link.to}
                    className="text-sm hover:text-emerald-700"
                    activeClassName="text-emerald-700"
                  >
                    {t(link.title)}
                  </Link>
                </li>
              ))}
              {userNavigation.map((link, i) => (
                <li key={i}>
                  <Link
                    to={link.to}
                    className="text-sm hover:text-emerald-700"
                    activeClassName="text-emerald-700"
                  >
                    {t(link.title)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="mb-4 font-semibold">{t('footer.title_information')}</h4>
            <ul>
              {legalNavigation.map((link, i) => (
                <li key={i}>
                  <a href={link.to} className="text-sm hover:text-emerald-700" /*download*/>
                    {t(link.title)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* <div>
            <h4 className="mb-4 font-semibold">{t('footer.title_languages')}</h4>
            <ul>
              {locales.map((locale, i) => (
                <li key={i}>
                  <Link
                    to={locale.path}
                    language={locale.language}
                    className="text-sm hover:text-emerald-700"
                  >
                    {locale.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}
        </div>
        <div className="mt-8 border-t pt-4 text-sm">© Austra - {currentYear}</div>
      </Container>
    </footer>
  );
};

export default Footer;
