import { useI18next } from 'gatsby-plugin-react-i18next';

type TLanguageMap = {
  [key: string]: string;
};

export const useLocales = () => {
  const { languages, originalPath, language } = useI18next();

  const languageMap: TLanguageMap = {
    fr: 'Français',
    en: 'English',
  };

  const locales = languages.map((lng) => ({
    name: languageMap[lng],
    path: originalPath,
    language: lng,
  }));

  return {
    locales,
    currentLocale: {
      name: languageMap[language],
      path: originalPath,
      language,
    },
  };
};
