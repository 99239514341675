import React from 'react';
import Footer from './Footer';
import Header from './Header';
type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  React.useEffect(() => {
    const setScreenHeight = () => {
      const screenH = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${screenH}px`);
    };

    setScreenHeight();

    window.addEventListener('resize', setScreenHeight);

    return () => {
      window.removeEventListener('resize', setScreenHeight);
    };
  }, []);

  return (
    <div className={`flex min-h-screen flex-col bg-white`}>
      <Header />
      <main className="flex grow flex-col">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
