import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Seo from '../../components/Seo';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import HeroInnerPage from '../../components/HeroInnerPage';
import Section from '../../components/Section';
import Button from '../../components/Button';
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types';
import SectionTitle from '../../components/SectionTitle';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import CarriersForm from '../../components/CarriersForm';
import { StaticImage } from 'gatsby-plugin-image';
const ProductsPolicyPage = ({ data }: any) => {
  const { t } = useTranslation();

  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;

  return (
    <Layout>
      <HeroInnerPage title={t('page.legal.products-policy.title')} />
      <Section>
        <Container>
          <div className="prose prose-h1:mt-0 prose-h2:mt-0 prose-h3:mt-0 prose-h4:mb-8">
            <h2>
              {t('page.legal.products-policy.finrest')}
            </h2>
            <p>
              {t('page.legal.products-policy.finrest.content')}
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default ProductsPolicyPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  const { language } = pageContext as PageContext;

  type Title = {
    [key: string]: string;
  };

  const title = {
    fr: 'Politique produits',
    en: 'Products policy',
  } as Title;

  return <Seo location={location} title={`${title[language]} | Austra`} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $language } }
        fileAbsolutePath: { regex: "/legal/products-policy/i" }
      }
    ) {
      nodes {
        frontmatter {
          title
        }
        id
      }
    }
  }
`;
