import React from 'react';

type Props = {};

const HeroBackground = (props: Props) => {
  return (
    <div className="pointer-events-none absolute inset-0 z-0 h-full w-full object-cover object-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="5062"
        height="2384"
        viewBox="0 0 5062 2384"
        className="absolute inset-0 h-full w-full opacity-80"
        preserveAspectRatio="xMinYMin slice"
      >
        <defs>
          <clipPath id="clipPath">
            <rect
              id="Rectangle_176"
              data-name="Rectangle 176"
              width="5062"
              height="2384"
              transform="translate(-7040 6573)"
              fill="#16217a"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#4fe9c7" />
            <stop offset="1" stopColor="#3872dd" />
          </linearGradient>
        </defs>
        <g id="Group_1472" data-name="Group 1472" transform="translate(1387 -6573)">
          <rect
            id="Rectangle_175"
            data-name="Rectangle 175"
            width="5062"
            height="2384"
            transform="translate(-1387 6573)"
            fill="#161f6f"
            opacity="0"
          />
          <g
            id="Mask_Group_3"
            data-name="Mask Group 3"
            transform="translate(5653)"
            clipPath="url(#clipPath)"
          >
            <path
              id="Path_7399"
              data-name="Path 7399"
              d="M6586.707,1956.926,6374.9,2475.957,6163.085,2993.8l-211.8,519.03L5379.545,3179.51l-573.323-333.323L3528.989,2103.349,2253.346,2846.188l-211.81-519.037,1487.453-865.445,1489.036,866.637,573.33,332.138,211.816-517.847-573.324-333.321L3528.989,820.059,1829.733,1809.314l-573.324,333.321,211.811,517.847,211.81,519.029,211.814,517.837,571.723-332.129v-1.192L3528.989,2745l1065.422,620.221,571.738,332.129,573.319,333.323-211.82,519.035-573.316-333.328L4382.6,3883.057l-853.613-496.412-853.611,496.412-571.732,332.137-571.727,333.32L1320.11,4030.67,1108.3,3512.832v-1.191L896.5,2993.8,684.68,2475.957v-1.185L472.87,1956.926l571.738-333.321,573.309-333.321L3528.989,178.41,5441.658,1291.475l573.316,332.13Z"
              transform="translate(-8038.788 5424.396)"
              opacity="0.103"
              fill="url(#linear-gradient)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default HeroBackground;
